<template>
    <div>
        <router-view/>
        <v-idle
            @idle="onidle"
            @remind="onremind"
            :loop="false"
            :reminders="[10, 15]"
            :wait="5"
            :duration="1800" />
    </div>
</template>

<script>
import { getAuth, signOut } from '@firebase/auth';

export default {
    mounted(){
        window.addEventListener('mouseup', event => {
            const sidebar = document.getElementById('sidebar');
            if (event.target != sidebar && event.target.parentNode != sidebar){
                this.$store.dispatch('mobileMenu', false);
            }
        });
    },
    methods: {
        logout(){
            const auth = getAuth();
            signOut(auth).then(() => {
                this.$router.push('/login');
            }).catch((error) => {
                console.log(error);
            });
        },
        onidle(){
            this.logout();
        },
        onremind(){
            console.log('remind');
        }
    }
}
</script>
