<template>
    <content-placeholders>
        <content-placeholders-heading :img="true" />
        <content-placeholders-text :lines="3" />
    </content-placeholders>
</template>

<script>
export default {   
}
</script>