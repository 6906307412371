import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import "../public/vendors/material-design-iconic-font/css/material-design-iconic-font.min.css";
import "../public/css/app.min.css";
import "../public/css/styles.scss";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

import { VTooltip, VPopover } from 'v-tooltip';
import { Datetime } from 'vue-datetime';
import TextareaAutosize from 'vue-textarea-autosize';
import Croppa from 'vue-croppa';
import VueIziToast from 'vue-izitoast';
import Multiselect from 'vue-multiselect';
import VueSweetalert2 from 'vue-sweetalert2';
import VueContentPlaceholders from 'vue-content-placeholders'
import VueProgressiveImage from 'vue-progressive-image'
import VLazyImage from "v-lazy-image/v2";
import Chart from 'vue-frappe-chart';
import NotFound from './components/NotFound';
import Spinner from './components/Spinner';
import Placeholder from './components/Placeholder';
import ToolbarEvents from './components/ToolbarEvents';
import Vidle from 'v-idle';
import 'vue-croppa/dist/vue-croppa.css';
import 'izitoast/dist/css/iziToast.css';
import 'vue-datetime/dist/vue-datetime.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'sweetalert2/dist/sweetalert2.min.css';


const firebaseConfig = {
  apiKey: "AIzaSyCgC1vTLRsccW-Pmdp-RZkrEkXeVtsKCbQ",
  authDomain: "smart-kast-votes.firebaseapp.com",
  projectId: "smart-kast-votes",
  storageBucket: "smart-kast-votes.appspot.com",
  messagingSenderId: "201278105224",
  appId: "1:201278105224:web:0ded3ab8729c9e759e213d"
};

const recaptchaKey = '6Le-pDYoAAAAACSjzcbMtdHPokX0aPMtXAH5thHd';


// Initialize Firebase
initializeApp(firebaseConfig);
const app = initializeApp(firebaseConfig);

initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(recaptchaKey),
  isTokenAutoRefreshEnabled: true
});


Vue.use(TextareaAutosize);
Vue.use(Croppa);
Vue.use(VueIziToast);
Vue.component('Datetime', Datetime);
Vue.use(VueContentPlaceholders);
Vue.use(VueProgressiveImage);
Vue.use(Chart);
Vue.use(VueSweetalert2);

Vue.component('Multiselect', Multiselect);
Vue.component('Spinner', Spinner);
Vue.component('Placeholder', Placeholder);
Vue.component('ToolbarEvents', ToolbarEvents);
Vue.component("VLazyImage", VLazyImage);
Vue.component('NotFound', NotFound);

Vue.config.productionTip = false;

Vue.directive("uppercase", {
  update: function (el) {
    el.value = el.value.toUpperCase()
  }
});

Vue.use(Vidle)


Vue.directive('tooltip', VTooltip)
Vue.component('v-popover', VPopover)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
