<template>
	<main class="main">        

		<AppHeader />

		<AppSidebar />

		<router-view />

	</main>
</template>

<script>
export default {
	components: {
		AppHeader: () => import('@/components/Header'),
		AppSidebar: () => import('@/components/Sidebar'),
	}
}
</script>
