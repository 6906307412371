<template>
    <div class="not-found">
        <i class="zmdi zmdi-close icon"></i>
        <div class="message">{{ message }}</div>
    </div>
</template>

<style scoped lang="scss">
    .not-found {
        margin: 50px auto;
        display: block;
        width: 100%;
        text-align: center;
        & .icon {
            font-size: 30px;
            color: #444444;
        }
        & .message {
            font-size: 14px;
            color: #444444;
        }
    }
</style>

<script>
export default {
    name: 'NotFound',
    props: {
        cols: {
            type: Number,
            default: 2
        },
        message: {
            type: String,
            default: 'Not results found'
        }
    }
}
</script>