import Vue from 'vue'
import VueRouter from 'vue-router'
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import Home from '../views/Home.vue';


Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: Home,
        children: [
            {
                path: '',
                component: () => import('../views/Dashboard/Index.vue'),
            },
            {
                path: 'events',
                component: () => import('../views/Events/List.vue'),
            },
            {
                path: 'events/add',
                component: () => import('../views/Events/Add.vue'),
            },
            {
                path: 'events/e/:eventId',
                component: () => import('../views/Events/View.vue'),
            },
            {
                path: 'events/e/:eventId/pricing',
                component: () => import('../views/Events/Pricing.vue'),
            },
            {
                path: 'events/e/:eventId/categories',
                component: () => import('../views/Events/Categories.vue'),
            },
            {
                path: 'events/e/:eventId/c/:categoryId/nominees',
                component: () => import('../views/Events/Nominees.vue'),
            },
            {
                path: 'events/e/:eventId/edit',
                component: () => import('../views/Events/Edit.vue'),
            },
            {
                path: 'events/e/:eventId/report',
                component: () => import('../views/Events/Report.vue'),
            },
            {
                path: 'events/e/:eventId/transactions',
                component: () => import('../views/Events/Transactions.vue'),
            },
            {
                path: 'events/e/:eventId/winners',
                component: () => import('../views/Events/Winners.vue'),
            },
            {
                path: 'events/e/:eventId/withdrawals',
                component: () => import('../views/Events/Withdrawals.vue'),
            },
            {
                path: 'events/e/:eventId/pending',
                component: () => import('../views/Events/Pending.vue'),
            },
            {
                path: 'organizers',
                component: () => import('../views/Organizers/Index.vue'),
            },
            {
                path: 'organizers/add',
                component: () => import('../views/Organizers/Add.vue'),
            },
            {
                path: 'organizers/o/:organizerId/edit',
                component: () => import('../views/Organizers/Edit.vue'),
            },
            {
                path: 'requisitions',
                component: () => import('../views/Requisitions/Index.vue'),
            },
            {
                path: 'settings',
                component: () => import('../views/Settings/Index.vue'),
            },
            {
                path: 'users',
                component: () => import('../views/Users/Index.vue'),
            },
            {
                path: 'users/add',
                component: () => import('../views/Users/Add.vue'),
            },
            {
                path: 'users/u/:userId',
                redirect: '/users'
            },
            {
                path: 'users/u/:userId/edit',
                component: () => import('../views/Users/Edit.vue'),
            },



            {
                path: 'reports',
                component: () => import('../views/Events/List.vue'),
            },

            {
                path: 'pricing',
                component: () => import('../views/Pricing/Index.vue'),
            },
            {
                path: 'nominations',
                redirect: "/nominations/t/artistes"
            },
            {
                path: 'nominations/t/:nominationType',
                component: () => import('../views/Nominations/Index.vue'),
            },
            {
                path: 'nominations/t/:nominationType/:nomineeId',
                component: () => import('../views/Nominations/View.vue'),
            },
            {
                path: 'transactions',
                component: () => import('../views/Transactions/Index.vue'),
            },
            {
                path: 'registrations',
                component: () => import('../views/Registrations/Index.vue'),
            },
            {
                path: 'registrations/:registrationId/transactions',
                component: () => import('../views/Registrations/Transactions.vue'),
            },
        ],
        meta: {
            isSecured: true,
        }
    },
    {
        path: '/login',
        component: () => import('../views/Auth/Login.vue'),
    },
    {
        path: '/forgot-password',
        component: () => import('../views/Auth/ForgotPassword.vue'),
    },
    {
        path: '*',
        component: () => import('../views/Events/List.vue'),
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
})

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.isSecured)) {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                next()
            } else {
                next('/login')
            }
        })
    } else {
        next()
    }
})


export default router