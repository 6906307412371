import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		user: {},
		menu: false,
	},
	mutations: {
		set_user: (state, user) => {
			state.user = user
		},
		set_menu: (state, menu) => {
			state.menu = menu
		}
	},
	actions: {
		setUser ({ commit }, user) {
			commit('set_user', user)
		},
		mobileMenu({ commit }, menu) {
			commit('set_menu', menu)
		},
	},
	modules: {
	},
	getters: {
		getUser: state => state.user,
		getMobileMenu: state => state.menu,
	},
	plugins: [createPersistedState()],
})
